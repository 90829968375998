<template>
  <div
    v-if="loaded"
    class="wrapper"
  >
    <div class="row">
      <div class="col-xl-10 col-lg-12">
        <h3 class="title">
          {{ $t('addPatient') }}
        </h3>
        <div class="subtitleBox">
          <span>{{ $t('addPatientChooseProgramTitle') }}</span>
        </div>
        <div class="programSelectBox">
          <form @submit.prevent="">
            <div class="row">
              <div class="col-10">
                <div class="form-group">
                  <label class="programName">{{ $t('programName') }}</label>
                  <multiselect
                    v-model="selectedProgram"
                    :options="filteredPrograms"
                    :show-labels="false"
                    :hide-selected="false"
                    :placeholder="$t('select')"
                    label="full_name"
                    track-by="id"
                    :allow-empty="true"
                    :preserve-search="currentBrowser.isExplorer"
                    :data-vv-as="$t('programName')"
                    name="program"
                    @select="selectProgram"
                  >
                    <template slot="caret">
                      <i class="fal fa-search" />
                    </template>
                    <template slot="noResult">
                      {{ $t('noResultsFound') }}
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col buttons">
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="cancelAndGoAway"
        >
          {{ $t('cancel') }}
        </button>
        <button
          v-if="selectedProgramId"
          type="button"
          class="btn btn-secondary"
          @click="goToAddPatient"
        >
          {{ $t('confirm') }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-secondary"
          @click="goToAddPatient"
        >
          {{ $t('skip') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import Multiselect from 'vue-multiselect'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { GET_PROGRAMS } from '@/store/modules/programs/constants.js'

const PRE_SELECTED_PROGRAM = 'programs/PRE_SELECTED_PROGRAM'

export default {
  name: 'AddPatientChooseProgram',
  components: {
    Multiselect
  },
  mixins: [Helpers, ErrorsDictionary, PatientHelper],
  props: [],
  data () {
    return {
      loaded: false,
      selectedProgram: {},
      selectedProgramId: null
    }
  },
  computed: {
    ...mapGetters({
      programs: 'programs',
      currentBrowser: 'getCurrentBrowser'
    }),
    filteredPrograms () {
      return this.programs
    }
  },
  created () {
    const loader = this.$loading.show()
    this.$store.dispatch(GET_PROGRAMS).finally(() => {
      loader.hide()
      this.loaded = true
    })
  },
  methods: {
    selectProgram (selectedProgram) {
      this.selectedProgramId = selectedProgram.id
      this.$store.commit(PRE_SELECTED_PROGRAM, this.selectedProgramId)
    },
    cancelAndGoAway () {
      this.$store.commit(PRE_SELECTED_PROGRAM, null)
      if (this.$parent.beforeClose) {
        this.$parent.beforeClose()
      }
    },
    goToAddPatient () {
      this.$router.push({ name: 'ClinicianAddPatientByProgram' })
    }
  }
}
</script>

<style scoped="scoped">

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
  }

  .programName {
    font-size: 14px;
  }

  .subtitleBox {
    margin: 30.1px 0 30px 12px;
  }

  .subtitleBox > span {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #0a3560;
  }

  .buttons {
    text-align: right;
    margin-top: 20px;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

  }

  .fa-search {
    position: absolute;
    width: 40px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease,
    -webkit-transform .2s ease;
    line-height: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    color: #9b9b9b;
  }

  .buttons > button {
    margin-left: 15px;
    width: 155px;
  }
</style>
